body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
    box-shadow: 0 5px 25px rgba(0, 0, 0, .15);
    padding: 1em;
    background-color: white;
    margin: 1em;
}

.year {
    width: 300px;
    min-height: 300px;
    background-color: white;
    margin: 1em;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.years {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.linechart {
    margin: 1em;
    width: 650px;
}

.reasons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.reason {
    background-color: white;
    margin: 0.2em;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .15);
}

.totals {
    margin: 1em;
}

.box .lead {
    margin-bottom: 0;
}

.box .h1, .box .h2, .box .h3, .box .h4, .box .h5, .box .h6, .box h1, .box h2, .box h3, .box h4, .box h5, .box h6 {
    margin-bottom: .0;
}

.title-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 1em;
}

.artists .year {
    min-height: min-intrinsic;
}

body {
    background-color: #e9ecef;
}

.footer {
    opacity: 0.4;
    margin: 2em;
}

/* .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
} */

.inputfile+label {
    display: inline-block;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}

.inputfile:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.react-calendar-heatmap .color-empty {
    fill: #EEEEEE;
}

.react-calendar-heatmap .color-scale-0 {
    fill: #EEEEEE;
}

.react-calendar-heatmap .color-scale-10 {
    fill: #DCE6EF;
}

.react-calendar-heatmap .color-scale-20 {
    fill: #CADEF0;
}

.react-calendar-heatmap .color-scale-30 {
    fill: #B8D6F2;
}

.react-calendar-heatmap .color-scale-40 {
    fill: #A6CFF3;
}

.react-calendar-heatmap .color-scale-50 {
    fill: #94C7F5;
}

.react-calendar-heatmap .color-scale-60 {
    fill: #82BFF6;
}

.react-calendar-heatmap .color-scale-70 {
    fill: #70B8F7;
}

.react-calendar-heatmap .color-scale-80 {
    fill: #5EB0F9;
}

.react-calendar-heatmap .color-scale-90 {
    fill: #4CA8FA;
}

.react-calendar-heatmap .color-scale-100 {
    fill: #3AA1FC;
}

.sk-fading-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}


.wrapped {
    background-color: #FF2525;
    padding: 2em;
    width: 700px;
    background-image: url('/background.jpg');
    display: block;
    background-size: 50%;
    background-repeat: repeat;
}
.wrapped .title {
    width: 100%
}

.wrapped .wrapped-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
 .wrapped .number {
     font-size: 4em;
     line-height: 0.9;
 }

 .wrapped h2, .wrapped h3, .wrapped div {
     padding: 0;
        margin: 0;
 }

 .wrapped .subtitle {
     text-transform: uppercase;
     font-size: 1em;
     padding-top: 20px;
     font-weight: 200;

 }
 .wrapped .small {
     margin-top: 10px;
 }
 .wrapped .item {
     font-weight: 600;
} 
 .wrapped .artist {
     opacity: 0.7;
     font-weight: 300;
 }
 
 .wrapped img {
     max-width: 150px;
     margin-top: 1em;
 }

 .shareButton {
    margin: 1em;
 }
